import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-select-modal',
  templateUrl: './select-modal.component.html',
  styleUrls: ['./select-modal.component.scss']
})
export class SelectModalComponent implements OnInit {
    @Input() fields = [
        { label: 'Año', options: ['Opción 1', '2020', 'Opción 3'], selectedValue:'2020', name: 'campo1' },
        { label: 'Marca', options: ['Opción 1', 'BMW', 'Opción 3'], selectedValue:'BMW', name: 'campo2' },
        { label: 'Modelo', options: ['Opción 1', 'Premier', 'Opción 3'], selectedValue:'Premier', name: 'campo3' },
        { label: 'Especialidad', options: ['Opción 1', 'Cambio llanta', 'Opción 3'], selectedValue:'Cambio llanta', name: 'campo4' },
        { label: 'Aro', options: ['Opción 1', '7', 'Opción 3'], selectedValue:'7', name: 'campo5' }
    ];
  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) { }

  ngOnInit(): void {
  }
    save() {
        this.activeModal.close(this.fields);
    }

    close() {
        this.activeModal.dismiss();
    }
}
