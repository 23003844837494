<app-navbar-style-one></app-navbar-style-one>

<div class="slider-area owl-carousel owl-theme">
    <div class="slider-item item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1>Explora y <b>elije el mejor servicio</b></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1>Encuentre los mejores talleres <b>de su elección</b></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1>Descubra el respuesto exacto <b>de su elección</b></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item item-bg4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1>Busca un repuesto y <b>elije un almacén</b></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="banner-form-area banner-form-mt">
    <div class="container">
        <div class="form-list text-center">
            <ul>
                <li class="active"><i class="flaticon-curve-arrow"></i> Todos</li>
                <li><i class="flaticon-curve-arrow"></i> Talleres</li>
                <li><i class="flaticon-curve-arrow"></i> Almacenes</li>
            </ul>
        </div>

        <div class="banner-form border-radius">
            <form>
                <div class="row">
                    <div class="col-lg-2 col-md-3">
                        <div class="form-group">
                            <i class='flaticon-box'></i>
                            <!--                            <input class="form-control" type="text" placeholder="Ubicación">-->
                            <select class="form-control">
                                <option>Ubicación</option>
                                <option>Norte</option>
                                <option>Centro</option>
                                <option>Sur</option>
                            </select>
                        </div>
                    </div>

                    <!--<div class="col-lg-1 col-md-1">
                        <div class="form-group">
                            <i class='flaticon-box'></i>
&lt;!&ndash;                            <input class="form-control" type="text" placeholder="Ubicación">&ndash;&gt;
                            <select class="form-control">
                                <option>Año</option>
                                <option>2020</option>
                                <option>2021</option>
                                <option>2022</option>
                                <option>2023</option>
                                <option>2024</option>
                            </select>
                        </div>
                    </div>-->

                    <div class="col-lg-2 col-md-3">
                        <div class="form-group">
                            <i class='flaticon-buildings'></i>
<!--                            <input class="form-control" type="text"  placeholder="¿Lo que están buscando?">-->
                            <select class="form-control">
                                <option>Marca</option>
                                <option>ALFA ROMEO</option>
                                <option>AUDI</option>
                                <option>BMW</option>
                                <option>CHEVROLET</option>
                                <option>CHRYSLER</option>
                                <option>CITROËN</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4">
                        <div class="form-group">
                            <i class='flaticon-deal'></i>
                            <select class="form-control">
                                <option>Modelo</option>
                                <option>4C SPIDER (960_)</option>
                                <option>A11 / A12 Caja/Chasis</option>
                                <option>GIULIA (952_)</option>
                                <option>GIULIA SPIDER (101_)</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4">
                        <div class="form-group">
                            <i class='flaticon-store'></i>
                            <select class="form-control">
                                <option>Especialidad</option>
                                <option>Pintura</option>
                                <option>Mecánica</option>
                                <option>Neumática</option>
                                <option>Electricidad</option>
                                <option>Chapa</option>
                                <option>Climatización</option>
                                <option>Inyección</option>
                                <option>Pre RV</option>
                                <option>Mentenimiento</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0">
                        <a type="submit" class="default-btn border-radius" routerLink="/listing">Buscar <i class="flaticon-loupe"></i></a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<section class="category-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Las categorías</span>
            <h2>Utilice la búsqueda rápida por categoría</h2>
        </div>

        <div class="row category-bg">
            <!--<div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a routerLink="/category"><i class="flaticon-bake"></i></a>
                    <a routerLink="/category"><h3>Talleres</h3></a>
                    <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                </div>
            </div>-->

            <div class="col-lg-6 col-sm-6">
                <div class="category-card">
                    <a routerLink="/category"><i class="flaticon-hotel"></i></a>
                    <a routerLink="/category"><h3>Almacenes de respuestos</h3></a>
                    <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                </div>
            </div>

            <!--<div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a routerLink="/category"><i class="flaticon-barbell"></i></a>
                    <a routerLink="/category"><h3>Fitness Club</h3></a>
                    <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                </div>
            </div>-->

            <div class="col-lg-6 col-sm-6">
                <div class="category-card">
                    <a routerLink="/category"><i class="flaticon-store"></i></a>
                    <a routerLink="/category"><h3>Talleres</h3></a>
                    <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                </div>
            </div>

            <!--<div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a routerLink="/category"><i class="flaticon-event"></i></a>
                    <a routerLink="/category"><h3>Events & Programme</h3></a>
                    <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a routerLink="/category"><i class="flaticon-flower"></i></a>
                    <a routerLink="/category"><h3>Beauty & Spa</h3></a>
                    <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                </div>
            </div>-->
        </div>
    </div>
</section>

<section class="place-list-area pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span>Directorio de talleres</span>
            <h2>Los últimos agregados</h2>
            <p>Porem ipsum dolor sit ame consectetur adipisicing eli sed usmod tempor</p>
        </div>

        <div class="place-slider owl-carousel owl-theme pt-45">
            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list1.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                        <h3>Por, Johnny</h3>
                    </div>

                    <div class="place-status bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Abierto ahora</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Taller</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>Sin freno y sin límite</h3></a>
                    <p><i class="flaticon-cursor"></i> Guayaquil, Guayas</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.9</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list2.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile2.png" alt="Images">
                        <h3>Por, Mariuxi</h3>
                    </div>

                    <div class="place-status bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Cerrado ahora</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"> <a routerLink="/listing-details">Almacén</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>Autoradiador S.A. - Importadora de accesorios y repuestos</h3></a>
                    <p><i class="flaticon-cursor"></i> Guayaquil, Guayas</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">5.0</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list3.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile3.png" alt="Images">
                        <h3>Por, Yocelyn</h3>
                    </div>

                    <div class="place-status bg-color-blue">
                        <a routerLink="/listing-details"><h3>Abierto ahora</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/listing-details"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/listing-details"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Almacén</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>Almacen De Repuestos Mendoza</h3></a>
                    <p><i class="flaticon-cursor"></i> Guayaquil, Guayas</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.5</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list4.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile4.png" alt="Images">
                        <h3>Por, Vanessa</h3>
                    </div>

                    <div class="place-status bg-color-blue">
                        <a routerLink="/listing-details"><h3>Abierto ahora</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Taller</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>Mansuera | Repuestos de Autos con Calidad y Garantía</h3></a>
                    <p><i class="flaticon-cursor"></i> Guayaquil, Guayas</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.7</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list1.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                        <h3>Por, Marco</h3>
                    </div>

                    <div class="place-status bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Abierto ahora</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Taller</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>Casanova Autopartes: Repuestos Automotrices</h3></a>
                    <p><i class="flaticon-cursor"></i> Guayaquil, Guayas</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.9</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="process-area process-bg pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Proceso</span>
            <h2>Vea cómo funciona</h2>
            <p>Porem ipsum dolor sit ame consectetur adipisicing incididunt </p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="process-card">
                    <i class="flaticon-position icon-bg"></i>
                    <h3>Encuentre el servicio deseado</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur adipisicing elit, sed do eiusmod tempor  quam voluptatem.</p>
                    <div class="process-number">1</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="process-card">
                    <i class="flaticon-to-do-list icon-bg"></i>
                    <h3>Elige el horario</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur adipisicing elit, sed do eiusmod tempor  quam voluptatem.</p>
                    <div class="process-number active">2</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="process-card">
                    <i class="flaticon-box icon-bg"></i>
                    <h3>Agende</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur adipisicing elit, sed do eiusmod tempor  quam voluptatem.</p>
                    <div class="process-number">3</div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="process-area process-bg pt-100 hp-section-row hp-section-row--dark hp-why-book-service pb-70">
    <div data-v-e79c41d0="" class="container"><h4 data-v-e79c41d0="" class="text-white text-center mb-2"></h4>
        <h1 data-v-e79c41d0="" class="mb-4 mb-md-5 display-2 text-white text-center">En caso de que te lo preguntes...</h1>
        <div data-v-e79c41d0="" class="row">
            <div data-v-e79c41d0="" class="col-md-6">
                <ul data-v-e79c41d0="" class="list-icons text-white">
                    <li data-v-e79c41d0="" class="d-flex">
                        <div data-v-e79c41d0="" class="svg-size">
                            <svg data-v-e79c41d0="" aria-hidden="true" focusable="false" data-prefix="fad"
                                 data-icon="comment-dollar" role="img" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 512 512" class="fa-2x mr-3 svg-inline--fa fa-comment-dollar fa-w-16"
                                 style="--fa-secondary-opacity: 1; --fa-primary-color: #ffa52a;">
                                <g data-v-e79c41d0="" class="fa-group">
                                    <path data-v-e79c41d0="" fill="currentColor"
                                          d="M256 32C114.62 32 0 125.12 0 240c0 49.56 21.41 95 57 130.74C44.46 421.05 2.7 466 2.2 466.5A8 8 0 0 0 8 480c66.26 0 116-31.75 140.6-51.38A304.66 304.66 0 0 0 256 448c141.38 0 256-93.12 256-208S397.38 32 256 32zm24 302.44V352a16 16 0 0 1-16 16h-16a16 16 0 0 1-16-16v-17.73a73 73 0 0 1-31.78-11.46c-6.22-4.11-6.82-13.11-1.55-18.38l17.52-17.52c3.74-3.74 9.31-4.24 14.11-2a24.52 24.52 0 0 0 10.26 2.22h32.78a8.43 8.43 0 0 0 2.32-16.53l-50.07-14.3c-22.25-6.35-40-24.71-42.91-47.67a56.27 56.27 0 0 1 49.32-63V128a16 16 0 0 1 16-16h16a16 16 0 0 1 16 16v17.73a73 73 0 0 1 31.78 11.46c6.22 4.11 6.82 13.11 1.55 18.38l-17.52 17.52c-3.74 3.74-9.31 4.24-14.11 2a24.54 24.54 0 0 0-10.26-2.22h-32.78a8.43 8.43 0 0 0-2.32 16.53l50.07 14.3c22.25 6.36 40 24.71 42.91 47.67A56.27 56.27 0 0 1 280 334.44z"
                                          class="fa-secondary"></path>
                                    <path data-v-e79c41d0="" fill="currentColor"
                                          d="M280 334.44V352a16 16 0 0 1-16 16h-16a16 16 0 0 1-16-16v-17.73a73 73 0 0 1-31.78-11.46c-6.22-4.11-6.82-13.11-1.55-18.38l17.52-17.52c3.74-3.74 9.31-4.24 14.11-2a24.52 24.52 0 0 0 10.26 2.22h32.78a8.43 8.43 0 0 0 2.32-16.53l-50.07-14.3c-22.25-6.35-40-24.71-42.91-47.67a56.27 56.27 0 0 1 49.32-63V128a16 16 0 0 1 16-16h16a16 16 0 0 1 16 16v17.73a73 73 0 0 1 31.78 11.46c6.22 4.11 6.82 13.11 1.55 18.38l-17.52 17.52c-3.74 3.74-9.31 4.24-14.11 2a24.54 24.54 0 0 0-10.26-2.22h-32.78a8.43 8.43 0 0 0-2.32 16.53l50.07 14.3c22.25 6.36 40 24.71 42.91 47.67A56.27 56.27 0 0 1 280 334.44z"
                                          class="fa-primary"></path>
                                </g>
                            </svg>
                        </div>
                        <span data-v-e79c41d0="">Autoalert es gratuito y no tiene cargos ni tarifas ocultos. Gratis para registrarse y utilizar.</span>
                    </li>
                    <li data-v-e79c41d0="" class="d-flex">
                        <div data-v-e79c41d0="" class="svg-size">
                            <svg data-v-e79c41d0="" aria-hidden="true" focusable="false" data-prefix="fad"
                                 data-icon="award" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
                                 class="fa-2x mr-3 svg-inline--fa fa-award fa-w-12"
                                 style="--fa-secondary-opacity: 1; --fa-primary-color: #ffa52a;">
                                <g data-v-e79c41d0="" class="fa-group">
                                    <path data-v-e79c41d0="" fill="currentColor"
                                          d="M382.78 448.69L324 304.23a40.63 40.63 0 0 1-22.23 15.62C280 325.78 278.26 324.44 263 340a39.72 39.72 0 0 1-49.73 5.82 39.68 39.68 0 0 0-42.53 0A39.73 39.73 0 0 1 121 340c-14.73-15-16.4-14-38.73-20.12a40.54 40.54 0 0 1-22.07-15.4L1.2 448.7a16 16 0 0 0 15.43 22l52.69-2 36.22 38.3a16 16 0 0 0 22.62.61A16.23 16.23 0 0 0 132 502l60.15-147.1L252 502a16 16 0 0 0 26.43 5l36.25-38.28 52.69 2A16 16 0 0 0 384 455.33a16.23 16.23 0 0 0-1.22-6.64zM192 240a64 64 0 1 0-64-64 64 64 0 0 0 64 64z"
                                          class="fa-secondary"></path>
                                    <path data-v-e79c41d0="" fill="currentColor"
                                          d="M366.62 152.72a41.51 41.51 0 0 0-10.42-39.59c-20.41-20.77-18.47-17.35-25.95-45.74a40.66 40.66 0 0 0-28.47-29c-27.88-7.61-24.52-5.62-45-26.41A39.79 39.79 0 0 0 218 1.39c-27.92 7.6-24 7.6-51.95 0A39.8 39.8 0 0 0 127.16 12c-20.41 20.78-17 18.8-44.94 26.41a40.66 40.66 0 0 0-28.47 29c-7.47 28.39-5.54 25-25.95 45.75a41.46 41.46 0 0 0-10.42 39.58c7.47 28.36 7.48 24.4 0 52.82a41.52 41.52 0 0 0 10.42 39.57c20.41 20.78 18.47 17.35 26 45.75a40.64 40.64 0 0 0 28.47 29C104.6 326 106.27 325 121 340a39.74 39.74 0 0 0 49.74 5.82 39.68 39.68 0 0 1 42.53 0A39.73 39.73 0 0 0 263 340c15.28-15.55 17-14.21 38.79-20.14a40.64 40.64 0 0 0 28.47-29c7.48-28.4 5.54-25 25.95-45.75a41.49 41.49 0 0 0 10.42-39.58c-7.48-28.36-7.49-24.4-.01-52.81zM192 272a96 96 0 1 1 96-96 96 96 0 0 1-96 96z"
                                          class="fa-primary"></path>
                                </g>
                            </svg>
                        </div>
                        <span
                            data-v-e79c41d0="">Cada centro de servicio para automóviles en Autoalert es cuidadosamente examinado.</span>
                    </li>
                    <li data-v-e79c41d0="" class="d-flex">
                        <div data-v-e79c41d0="" class="svg-size">
                            <svg data-v-e79c41d0="" aria-hidden="true" focusable="false" data-prefix="fad"
                                 data-icon="badge-check" role="img" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 512 512" class="fa-2x mr-3 svg-inline--fa fa-badge-check fa-w-16"
                                 style="--fa-secondary-opacity: 1; --fa-primary-color: #ffa52a;">
                                <g data-v-e79c41d0="" class="fa-group">
                                    <path data-v-e79c41d0="" fill="currentColor"
                                          d="M512 256a88 88 0 0 0-57.1-82.4A88 88 0 0 0 338.4 57.1a88 88 0 0 0-164.8 0A88 88 0 0 0 57.1 173.6a88 88 0 0 0 0 164.8 88 88 0 0 0 116.5 116.5 88 88 0 0 0 164.8 0 88 88 0 0 0 116.5-116.5A88 88 0 0 0 512 256zm-144.8-44.25l-131 130a11 11 0 0 1-15.55-.06l-75.72-76.33a11 11 0 0 1 .06-15.56L171 224a11 11 0 0 1 15.56.06l42.15 42.49 97.2-96.42a11 11 0 0 1 15.55.06l25.82 26a11 11 0 0 1-.08 15.56z"
                                          class="fa-secondary"></path>
                                    <path data-v-e79c41d0="" fill="currentColor"
                                          d="M367.2 211.75l-131 130a11 11 0 0 1-15.55-.06l-75.72-76.33a11 11 0 0 1 .06-15.56L171 224a11 11 0 0 1 15.56.06l42.15 42.49 97.2-96.42a11 11 0 0 1 15.55.06l25.82 26a11 11 0 0 1-.06 15.56z"
                                          class="fa-primary"></path>
                                </g>
                            </svg>
                        </div>
                        <span
                            data-v-e79c41d0="">Todos los servicios cuentan con la tranquilidad de garantizar su satisfacción.</span>
                    </li>
                </ul>
            </div>
            <div data-v-e79c41d0="" class="col-md-6">
                <ul data-v-e79c41d0="" class="list-icons text-white">
                    <li data-v-e79c41d0="" class="d-flex">
                        <div data-v-e79c41d0="" class="svg-size">
                            <svg data-v-e79c41d0="" aria-hidden="true" focusable="false" data-prefix="fad"
                                 data-icon="tags" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"
                                 class="fa-2x mr-3 svg-inline--fa fa-tags fa-w-20"
                                 style="--fa-secondary-opacity: 1; --fa-primary-color: #ffa52a;">
                                <g data-v-e79c41d0="" class="fa-group">
                                    <path data-v-e79c41d0="" fill="currentColor"
                                          d="M497.94 225.94L286.06 14.06A48 48 0 0 0 252.12 0H48A48 48 0 0 0 0 48v204.12a48 48 0 0 0 14.06 33.94l211.88 211.88a48 48 0 0 0 67.88 0l204.12-204.12a48 48 0 0 0 0-67.88zM112 160a48 48 0 1 1 48-48 48 48 0 0 1-48 48z"
                                          class="fa-secondary"></path>
                                    <path data-v-e79c41d0="" fill="currentColor"
                                          d="M625.94 293.82L421.82 497.94a48 48 0 0 1-67.88 0l-.36-.36 174.06-174.06a90 90 0 0 0 0-127.28L331.4 0h48.72a48 48 0 0 1 33.94 14.06l211.88 211.88a48 48 0 0 1 0 67.88z"
                                          class="fa-primary"></path>
                                </g>
                            </svg>
                        </div>
                        <span data-v-e79c41d0="">Obtén hasta un 25% de descuentos en servicios automotrices suscribiéndote a Autoalert+.</span>
                    </li>
                    <li data-v-e79c41d0="" class="d-flex">
                        <div data-v-e79c41d0="" class="svg-size">
                            <svg data-v-e79c41d0="" aria-hidden="true" focusable="false" data-prefix="fad"
                                 data-icon="credit-card-front" role="img" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 576 512" class="fa-2x mr-3 svg-inline--fa fa-credit-card-front fa-w-18"
                                 style="--fa-secondary-opacity: 1; --fa-primary-color: #ffa52a;">
                                <g data-v-e79c41d0="" class="fa-group">
                                    <path data-v-e79c41d0="" fill="currentColor"
                                          d="M268 256h-64a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12h64a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12zm-104 0H76a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12zm208 0h-64a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12h64a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12zm128 0h-88a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12z"
                                          class="fa-secondary"></path>
                                    <path data-v-e79c41d0="" fill="currentColor"
                                          d="M528 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zM192 268a12 12 0 0 1 12-12h64a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12h-64a12 12 0 0 1-12-12zm-32 136a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h72a12 12 0 0 1 12 12zm16-96a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h88a12 12 0 0 1 12 12zm176 96a12 12 0 0 1-12 12H204a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h136a12 12 0 0 1 12 12zm32-96a12 12 0 0 1-12 12h-64a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h64a12 12 0 0 1 12 12zm128 0a12 12 0 0 1-12 12h-88a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h88a12 12 0 0 1 12 12zm0-140a23.94 23.94 0 0 1-24 24h-80a23.94 23.94 0 0 1-24-24v-48a23.94 23.94 0 0 1 24-24h80a23.94 23.94 0 0 1 24 24z"
                                          class="fa-primary"></path>
                                </g>
                            </svg>
                        </div>
                        <span data-v-e79c41d0="">No necesita una tarjeta de crédito ni ningún método de pago para programar un servicio.</span>
                    </li>
                    <li data-v-e79c41d0="" class="d-flex">
                        <div data-v-e79c41d0="" class="svg-size">
                            <svg data-v-e79c41d0="" aria-hidden="true" focusable="false" data-prefix="fad"
                                 data-icon="calendar" role="img" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 448 512" class="fa-2x mr-3 svg-inline--fa fa-calendar fa-w-14"
                                 style="--fa-secondary-opacity: 1; --fa-primary-color: #ffa52a;">
                                <g data-v-e79c41d0="" class="fa-group">
                                    <path data-v-e79c41d0="" fill="currentColor"
                                          d="M0 192v272a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V192zm304-64h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16zm-192 0h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16z"
                                          class="fa-secondary"></path>
                                    <path data-v-e79c41d0="" fill="currentColor"
                                          d="M448 112v80H0v-80a48 48 0 0 1 48-48h48v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h128v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h48a48 48 0 0 1 48 48z"
                                          class="fa-primary"></path>
                                </g>
                            </svg>
                        </div>
                        <span data-v-e79c41d0="">Tenga la seguridad de que todas las citas se confirman directamente con los centros de servicio.</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="city-area ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <span>Ofertas</span>
            <h2>Explore nuestras ofertas</h2>
            <p> </p>
        </div>

        <div class="product-area pt-100 pb-70">
            <div class="container">
                <div class="row">

                    <div class="col-lg-4 col-md-6">
                        <div class="product-card">
                            <a routerLink="/products-details"><img src="assets/img/products/product7.jpg" alt="Images"></a>
                            <div class="product-content">
                                <ul class="rating">
                                    <li>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </li>
                                </ul>
                                <ul>
                                    <!--<li><del>$30.00</del></li>-->
                                    <li>25 %</li>
                                </ul>
                                <a routerLink="/products-details"><h3>Cambios de aceite</h3></a>
                                <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Explorar</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="product-card">
                            <a routerLink="/products-details"><img src="assets/img/products/product8.jpg" alt="Images"></a>
                            <div class="product-content">
                                <ul class="rating">
                                    <li>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </li>
                                </ul>
                                <ul>
                                    <!--<li><del>$40.00</del></li>-->
                                    <li>35 %</li>
                                </ul>
                                <a routerLink="/products-details"><h3>Lubricación</h3></a>
                                <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Explorar</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div class="product-card">
                            <a routerLink="/products-details"><img src="assets/img/products/product9.jpg" alt="Images"></a>
                            <div class="product-content">
                                <ul class="rating">
                                    <li>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </li>
                                </ul>
                                <ul>
                                    <!--<li><del>$30.00</del></li>-->
                                    <li>25 %</li>
                                </ul>
                                <a routerLink="/products-details"><h3>Cambio de llantas</h3></a>
                                <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Explorar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="video-area video-area-bg">
    <div class="container modal-dialog-centered">
        <!--<div class="video-content">
            <h2>¿Estás listo para comenzar tu viaje?</h2>
            <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="play-btn"><i class='bx bx-play' ></i></a>
        </div>-->
        <video width="1077" height="240" controls>
            <source src="assets/img/Auto.mp4" type="video/mp4">
            Tu navegador no soporta el elemento <code>video</code>.
        </video>

    </div>
</div>

<div class="counter-area">
    <div class="container">
        <div class="counter-bg">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>1254</h3>
                        <span>Nuevos visitantes cada semana</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>23165</h3>
                        <span>Nuevos visitantes cada día</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>4563</h3>
                        <span>Pemios increíbles ganados</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>880</h3>
                        <span>Nuevo listado cada semana</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="place-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6">
                <div class="section-title mb-45">
                    <span>Negocios populares</span>
                    <h2>Los más populares</h2>
                </div>
            </div>

            <div class="col-lg-5 col-md-6">
                <div class="place-btn">
                    <a routerLink="/listing" class="default-btn border-radius">Ver todos <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area1.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.9</a></li>
                            <li>
                                <span>23 Reseñas</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Cerrado ahora</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile.png" alt="Images">
                            <h3>Por, Gonzalo Tozzi</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> Guayaquil, Guayas</span>
                        <a routerLink="/listing-details"><h3>Lleva tu respuesto varón </h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"> <a href="#">$560-890</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area2.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>30 Reseñas</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-blue">
                        <a routerLink="/listing-details"><h3>Abierto ahora</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile2.png" alt="Images">
                            <h3>Por, Venustacio</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> Guayaquil, Guayas</span>
                        <a routerLink="/listing-details"><h3>La Reparación Exitosa</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a href="#">$500-700</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area3.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.9</a></li>
                            <li>
                                <span>23 Reseñas</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-heliotrope">
                        <a routerLink="/listing-details"><h3>Abierto ahora</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile3.png" alt="Images">
                            <h3>Por, Umberto Osborne</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> Guayaquil, Guayas</span>
                        <a routerLink="/listing-details"><h3>Reparándolo inmediatamente</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/listing-details">$400-800</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area4.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.8</a></li>
                            <li>
                                <span>16 Reseñas</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Cerrado ahora</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile4.png" alt="Images">
                            <h3>Por, Laura Cinquetti</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> Guayaquil, Guayas</span>
                        <a routerLink="/listing-details"><h3>Tu respuesto barato</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a href="#">$560-890</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area5.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>16 Reseñas</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-blue">
                        <a routerLink="/listing-details"><h3>Cerrado ahora</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile5.png" alt="Images">
                            <h3>Por, Dylania Manzano</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> Guayaquil, Guayas</span>
                        <a routerLink="/listing-details"><h3>El más barato que nadie</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a href="#">$300-600</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area6.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>39 Reseñas</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-green">
                        <a routerLink="/listing-details"><h3>Abierto ahora</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile6.png" alt="Images">
                            <h3>Por, Cloromiro Abramzon</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> Guayaquil, Guayas</span>
                        <a routerLink="/listing-details"><h3>El gran taller mecánico</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a href="#">$400-800</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!---->
<div class="application-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="application-img">
                    <img src="assets/img/mobile.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-7">
                <div class="application-content">
                    <div class="section-title">
                        <span>Descargar aplicación</span>
                        <h2>Obtenga más en nuestra aplicación Siéntese y disfrute</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                    </div>

                    <div class="application-btn">
                        <a href="https://servicarbook.com/magiso.apk" class="application-play-btn">
                            <img src="assets/img/google-play.svg" alt="svg">
                            <div class="btn-content">
                                <span>APL. ANDROID EN</span>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                        <a href="https://servicarbook.com/tvp.apk" class="application-play-btn">
                            <img src="assets/img/company.svg" alt="svg">
                            <div class="btn-content">
                                <span>DESCARGAR EN LA</span>
                                <h3>App Store</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--
<section class="testimonial-area pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span>Testimonios</span>
            <h2>Lo que dicen nuestros clientes</h2>
        </div>

        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-item testimonial-item-bg">
                <h3>Lucio Batisti</h3>
                <span>Transporte Amigo SA</span>
                <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>

            <div class="testimonial-item testimonial-item-bg">
                <h3>Andy Williams</h3>
                <span>Expreso escolar SA</span>
                <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>

            <div class="testimonial-item testimonial-item-bg">
                <h3>Soraya Laredo</h3>
                <span>Taxi Ruta</span>
                <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>

            <div class="testimonial-item testimonial-item-bg">
                <h3>Carlos Mata</h3>
                <span>Transportes del Litoral</span>
                <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>

            <div class="testimonial-item testimonial-item-bg">
                <h3>Sandro Marín</h3>
                <span>Supertransportes</span>
                <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>
        </div>
    </div>
</section>
-->

<app-footer-style-one></app-footer-style-one>
