<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-banner-title text-center">
            <h3>Resultado</h3>
            <p>Para su auto tenemos las siguintes opciones</p>
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="index.html">Inicio</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Resultado</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-5">
                    <p>Resultados para: <a routerLink="/">Mantenimientos</a></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="listing-widget-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="listing-section-right">
                    <h3 class="title"> <i class="flaticon-filter"></i> Filtros</h3>

                    <div class="listing-right-form">
                        <form>
                            <div class="form-group">
                                <i class='flaticon-loupe'></i>
                                <input type="text" class="form-control" placeholder="Algo específico*">
                            </div>
                            <div class="form-group">
                                <i class='flaticon-filter'></i>
                                <select class="form-control">
                                    <option>Servicios</option>
                                    <option>Frenado</option>
                                    <option>Filtracion</option>
                                    <option>Suspension</option>
                                    <option>Embrague</option>
                                    <option>Motor</option>
                                    <option>Exteriores</option>
                                    <option>Escape</option>
                                    <option>Electricidad</option>
                                    <option>Refrigeracion</option>
                                    <option>Climatizacion</option>
                                </select>
                            </div>
                            <!--<div class="form-group">
                            <i class='flaticon-menu'></i>
                            <input type="text" class="form-control" placeholder="All Cities">
                        </div>-->
                            <div class="form-group">
                                <i class='flaticon-list'></i>
                                <select class="form-control">
                                    <option>Todas las ciudades</option>
                                    <option>Guayaquil</option>
                                    <option>Quito</option>
                                    <option>Cuenca</option>
                                </select>
                            </div>
                            <!--<div class="form-group">
                                <i class="flaticon-calendar"></i>
                                <input type="text" class="form-control" placeholder="Date & Time">
                            </div>-->
                        </form>
                    </div>

                    <!--<div class="facilities-list">
                        <h3 class="facilities-title"> Facilities</h3>

                        <ul>
                            <li>
                                <div class="agree-label">
                                    <input type="checkbox" id="chb1">
                                    <label for="chb1">Air Conditioned</label>
                                </div>
                            </li>
                            <li>
                                <div class="agree-label">
                                    <input type="checkbox" id="chb1">
                                    <label for="chb1">Non-smoking</label>
                                </div>
                            </li>
                            <li>
                                <div class="agree-label">
                                    <input type="checkbox" id="chb1">
                                    <label for="chb1">Elevator building</label>
                                </div>
                            </li>
                            <li>
                                <div class="agree-label">
                                    <input type="checkbox" id="chb1">
                                    <label for="chb1">Free Parking</label>
                                </div>
                            </li>
                            <li>
                                <div class="agree-label">
                                    <input type="checkbox" id="chb1">
                                    <label for="chb1">Pet Friendly</label>
                                </div>
                            </li>
                            <li>
                                <div class="agree-label">
                                    <input type="checkbox" id="chb1">
                                    <label for="chb1">Free WiFi</label>
                                </div>
                            </li>
                        </ul>
                    </div>-->

                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn border-radius">Aplicar <i class='bx bx-plus'></i></button>
                    </div>
                </div>
            </div>

            <div class="col-lg-8">
                <div class="listing-widget-into">
                    <div class="row">
                        <div class="col-lg-9 col-sm-9">
                            <div class="listing-right-form">
                                <div class="row">
                                    <div class="form-group">
                                        <label>Ordenar por:</label>
                                    </div>
                                    <div class="col-lg-7 col-sm-8">
                                        <div class="form-group">
                                            <i class='flaticon-filter'></i>
                                            <select class="form-control">
                                                <option>Destacados</option>
                                                <option>Promedio Opinión del cliente</option>
                                                <option>Rango precio descendente</option>
                                                <option>Rango precio ascendente</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-3">
                            <ul class="listing-widget-menu">
                                <li><a routerLink="/listing" class="active"><i class="flaticon-filter"></i></a></li>
                                <li><a routerLink="/listing"><i class="flaticon-menu"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="place-list-item">
                                <div class="images">
                                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list1.jpg" alt="Images"></a>

                                    <div class="place-profile">
                                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                                        <h3>$30 - $ 60</h3>
                                    </div>

                                    <div class="place-status bg-dark-orange">
                                        <a routerLink="/listing-details"><h3>Abierto</h3></a>
                                    </div>

                                    <div class="place-tag">
                                        <ul>
                                            <!--<a class="default-btn border-radius"
                                               routerlink="/listing"
                                               href="/pilar/listing">Cotizar</a>-->
                                            <button class="btn btn-primary" (click)="openModal()">Cotizar</button>
                                        </ul>
                                        <br>
                                        <span><a href="#">Horario: Lunes-Viernes 07:00-19:00</a></span>
                                        <h3 class="title"><a routerLink="/listing-details">Reparauto</a></h3>
                                    </div>
                                </div>

                                <div class="content">
                                    <a routerLink="/listing-details"><h3>Reparauto</h3></a>
                                    <p><i class="flaticon-cursor"></i> Victor Emilio Estrada, Guayaquil</p>
                                    <ul class="place-rating">
                                        <li><a routerLink="/listing">4.9</a></li>
                                        <li>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star icon-color'></i>
                                            <i class='bx bxs-star icon-color'></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="place-list-item">
                                <div class="images">
                                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list2.jpg" alt="Images"></a>

                                    <div class="place-profile">
                                        <img src="assets/img/place-list/place-profile2.png" alt="Images">
                                        <h3>$30 - $ 60</h3>
                                    </div>

                                    <div class="place-status bg-dark-orange">
                                        <a routerLink="/listing-details"><h3>Cerrado</h3></a>
                                    </div>

                                    <div class="place-tag">
                                        <span><a href="#">Horario: Lunes-Viernes 07:00-19:00</a></span>
                                        <h3 class="title"> <a href="#">Mecasur</a></h3>
                                    </div>
                                </div>

                                <div class="content">
                                    <a routerLink="/listing-details"><h3>Mecasur</h3></a>
                                    <p><i class="flaticon-cursor"></i> Victor Emilio Estrada, Guayaquil</p>
                                    <ul class="place-rating">
                                        <li><a routerLink="/listing">5.0</a></li>
                                        <li>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="place-list-item">
                                <div class="images">
                                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list3.jpg" alt="Images"></a>

                                    <div class="place-profile">
                                        <img src="assets/img/place-list/place-profile3.png" alt="Images">
                                        <h3>$30 - $ 60</h3>
                                    </div>

                                    <div class="place-status bg-color-blue">
                                        <a routerLink="/listing-details"><h3>Abierto</h3></a>
                                    </div>

                                    <div class="place-tag">
                                        <span><a href="#">Horario: Lunes-Viernes 07:00-19:00</a></span>
                                        <h3 class="title"><a href="#">IBAUTO</a></h3>
                                    </div>
                                </div>

                                <div class="content">
                                    <a routerLink="/listing-details"><h3>IBAUTO</h3></a>
                                    <p><i class="flaticon-cursor"></i> Victor Emilio Estrada, Guayaquil</p>
                                    <ul class="place-rating">
                                        <li><a routerLink="/listing">4.5</a></li>
                                        <li>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star icon-color'></i>
                                            <i class='bx bxs-star icon-color'></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="place-list-item">
                                <div class="images">
                                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list4.jpg" alt="Images"></a>

                                    <div class="place-profile">
                                        <img src="assets/img/place-list/place-profile4.png" alt="Images">
                                        <h3>$30 - $ 60</h3>
                                    </div>

                                    <div class="place-status bg-color-blue">
                                        <a routerLink="/listing-details"><h3>Abierto</h3></a>
                                    </div>

                                    <div class="place-tag">
                                        <span><a href="#">Horario: Lunes-Viernes 07:00-19:00</a></span>
                                        <h3 class="title"><a href="#">Crispasfor</a></h3>
                                    </div>
                                </div>

                                <div class="content">
                                    <a routerLink="/listing-details"><h3>Crispasfor</h3></a>
                                    <p><i class="flaticon-cursor"></i> Victor Emilio Estrada, Guayaquil</p>
                                    <ul class="place-rating">
                                        <li><a routerLink="/listing">4.7</a></li>
                                        <li>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star icon-color'></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="place-list-item">
                                <div class="images">
                                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list5.jpg" alt="Images"></a>

                                    <div class="place-profile">
                                        <img src="assets/img/place-list/place-profile2.png" alt="Images">
                                        <h3>$30 - $ 60</h3>
                                    </div>

                                    <div class="place-status bg-color-blue">
                                        <a routerLink="/listing-details"><h3>Abierto</h3></a>
                                    </div>

                                    <div class="place-tag">
                                        <span><a href="#">Horario: Lunes-Viernes 07:00-19:00</a></span>
                                        <h3 class="title"><a href="#">Manteauto</a></h3>
                                    </div>
                                </div>

                                <div class="content">
                                    <a routerLink="/listing-details"><h3>Manteauto</h3></a>
                                    <p><i class="flaticon-cursor"></i> Victor Emilio Estrada, Guayaquil </p>
                                    <ul class="place-rating">
                                        <li><a routerLink="/listing">4.7</a></li>
                                        <li>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star icon-color'></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="place-list-item">
                                <div class="images">
                                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list6.jpg" alt="Images"></a>

                                    <div class="place-profile">
                                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                                        <h3>$30 - $ 60</h3>
                                    </div>

                                    <div class="place-status bg-color-blue">
                                        <a routerLink="/listing-details"><h3>Abierto</h3></a>
                                    </div>

                                    <div class="place-tag">
                                        <span><a href="#">Horario: Lunes-Viernes 07:00-19:00</a></span>
                                        <h3 class="title"><a href="#">Jose Ramos</a></h3>
                                    </div>
                                </div>

                                <div class="content">
                                    <a routerLink="/listing-details"><h3>Jose Ramos</h3></a>
                                    <p><i class="flaticon-cursor"></i> Victor Emilio Estrada, Guayaquil</p>
                                    <ul class="place-rating">
                                        <li><a routerLink="/listing">4.7</a></li>
                                        <li>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star icon-color'></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 text-center">
                            <a routerLink="/listing" class="default-btn border-radius">Cargar Más <i class='bx bx-plus'></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
