<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-content">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="inner-banner-text">
                        <h3>Taller sin límite y sin freno</h3>
                        <ul>
                            <li><i class="flaticon-cursor"></i>Guayaquil, Guayas, Ecuador</li>
                            <li><i class="flaticon-telephone"></i> <a href="tel:+6(567)876543">+593(567)876543</a></li>
                            <li><i class="flaticon-email-1"></i> <a href="#">contacto@taller.com</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="banner-rating">
                        <ul>
                            <li class="rating-star">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span>4 Reseñas</span>
                            </li>
                            <li><a routerLink="/listing-details">4.9</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-bottom">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="banner-tag">
                        <ul>
                            <li><a routerLink="/listing-details"><i class="flaticon-favorite"></i>Agendar ahora</a></li>
                            <li><a routerLink="/listing-details"><i class="flaticon-writing"></i> Agregar reseña</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="banner-profile">
                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                        <h3>$100 - $200</h3>
                        <div class="banner-status">
                            <a routerLink="/listing-details"><h3>Abierto ahora</h3></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="index.html">Inicio</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Taller sin límite y sin freno</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-5">
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="listing-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="listing-details-wrap">
                    <div class="listing-category">
                        <ul>
                            <li><a routerLink="/listing-details"><i class="flaticon-touch"></i>DETALLES</a></li>
                            <li><a routerLink="/listing-details"><i class="flaticon-gallery"></i> GALERIA</a></li>
                            <li><a routerLink="/listing-details"><i class="flaticon-writing"></i> RESEÑAS</a></li>
                        </ul>
                        <h3><i class="flaticon-worldwide"></i> MAPA</h3>
                    </div>
                    <div class="listing-widget listing-widget-desc">
                        <h3 class="title">Descripción</h3>
                        <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidunt ut labore et dolore plicabo.Nemo enim ipsam voluptatem quia voluptas sit aspertur aut odit aut quia csqntur magni dolores eos qui ratione voluptatem sequi nesci</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore plicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur tur magni lores eos qui ratione voluptatem sequi nesciunt.</p>
                        <a routerLink="/listing-details" class="default-btn border-radius">Visit Website <i class='bx bx-plus'></i></a>
                        <div class="listing-features">
                            <h3 class="features-title">Características</h3>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <img src="assets/img/ico1.png" alt="" class="e">
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <img src="assets/img/ico2.png" alt="" class="e">
                                </div>
                                <!--<div class="col-lg-3 col-md-3">
                                    <div class="features-card">
                                        <i class="flaticon-coffee-cup"></i>
                                        <h3>Pintado</h3>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="features-card">
                                        <i class="flaticon-wifi"></i>
                                        <h3>Lavado</h3>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="features-card">
                                        <i class="flaticon-parking"></i>
                                        <h3>Parqueo</h3>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="features-card">
                                        <i class="flaticon-bread"></i>
                                        <h3>Cambio aceite</h3>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                    </div>
                    <div class="listing-widget gallery-slider">
                        <h3 class="title">Galeria / Fotos</h3>

                        <div class="gallery-slider-area owl-carousel owl-theme">
                            <div class="Gallery-item">
                                <img src="assets/img/place-list/listing-details1.jpg" alt="Images">
                            </div>

                            <div class="Gallery-item">
                                <img src="assets/img/place-list/listing-details2.jpg" alt="Images">
                            </div>

                            <div class="Gallery-item">
                                <img src="assets/img/place-list/listing-details3.jpg" alt="Images">
                            </div>

                            <div class="Gallery-item">
                                <img src="assets/img/place-list/listing-details4.jpg" alt="Images">
                            </div>
                        </div>
                    </div>

                    <div class="listing-widget listing-map">
                        <h3 class="title">Ubicación del mapa</h3>

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31895.73168450137!2d-79.95674730842146!3d-2.166461754574938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d726bda5f0f47%3A0x7815737f981131bc!2sLos%20Ceibos%2C%20Guayaquil!5e0!3m2!1ses-419!2sec!4v1715911580362!5m2!1ses-419!2sec" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <div class="listing-widget">
                        <h3 class="title">Reseñas</h3>

                        <div class="listing-widget-review">
                            <ul>
                                <li class="rating-star">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <span>Buena</span>
                                </li>
                                <li><a routerLink="/listing-details">4.5</a></li>
                            </ul>
                        </div>

                        <div class="progressbar-area">
                            <div class="single-progressbar">
                                <h3>Calidad</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-1">5.0</div>
                                    <div class="progressbar-circle pc-1"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Ubicación</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-2">5.5</div>
                                    <div class="progressbar-circle pc-2"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Price</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-3">4.7</div>
                                    <div class="progressbar-circle pc-3"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Service</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-4">4.5</div>
                                    <div class="progressbar-circle pc-4"></div>
                                </div>
                            </div>
                        </div>

                        <div class="listing-comment">
                            <ul>
                                <li>
                                    <img src="assets/img/place-list/listing-details-pro1.png" alt="Image">
                                    <div class="content">
                                        <h3>Sergio Tarkan</h3>
                                        <span>Enero 12, 2024</span>
                                        <p>Buen servicio</p>
                                        <div class="comment-rating">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <span>4.5</span>
                                        </div>
                                        <a routerLink="/listing-details">Responder</a>
                                        <div class="view-list">
                                            Moderador IA
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <img src="assets/img/place-list/listing-details-pro2.png" alt="Image">
                                    <div class="content">
                                        <h3>Jandy Edurne </h3>
                                    <span>Julio 12, 2023</span>
                                    <p>Me atendieron muy bien</p>
                                        <div class="comment-rating">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <span>4.5</span>
                                        </div>
                                        <a routerLink="/listing-details">Responder</a>
                                        <div class="view-list">
                                            Moderador IA
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="listing-widget progressbar-area-title">
                        <h3 class="title">Agregar una opinión</h3>

                        <div class="progressbar-area progressbar-bg">
                            <div class="single-progressbar">
                                <h3>Calidad</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-1">5.0</div>
                                    <div class="progressbar-circle pc-1"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Ubicación</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-2">5.5</div>
                                    <div class="progressbar-circle pc-2"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Precio</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-3">4.7</div>
                                    <div class="progressbar-circle pc-3"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Servicio</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-4">4.5</div>
                                    <div class="progressbar-circle pc-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="listing-widget-contact">
                        <div class="listing-contact-title">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="listing-widget-mail">
                                        <i class="flaticon-email-2"></i>
                                        <div class="content">
                                            <h3>No te preocupes</h3>
                                            <span>Su correo electrónico está seguro con nosotros.</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 text-end">
                                    <div class="listing-contact-review">
                                        <ul>
                                            <li class="rating-star">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <span>Calificación</span>
                                            </li>
                                            <li><a href="#">4.5</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="contact-form">
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="text" name="name" id="name" class="form-control" required placeholder="Su nombre*">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="email" name="email" id="email" class="form-control" required placeholder="Correo electrónico*">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Su mensaje"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn border-radius">Enviar comentario <i class='bx bx-plus'></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="listing-details-side">
                    <div class="listing-widget-side">
                        <h3 class="title">Reserva tu cita</h3>

                        <div class="listing-Booking-form">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="text" name="name" id="name" class="form-control" required placeholder="Su nombre*">
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='flaticon-email-2'></i>
                                            <input type="email" name="email" id="email" class="form-control" required placeholder="Correo electrónico*">
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='bx bx-phone'></i>
                                            <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Su teléfono">
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <button class="calendar" (click)="d.toggle()" type="button"><i class="flaticon-calendar"></i></button>
                                            <!--<input type="text" class="form-control" placeholder="Fecha y hora">-->
                                            <input id="dp1" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #d="ngbDatepicker"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Su mensaje"></textarea>
                                        </div>
                                    </div>

                                    <div class="includes"><strong class="">El mantenimiento de 50.000km incluye</strong>:
                                        <ul class="row pt-2">
                                            <li class="col-12"><span class="maintenance-work"
                                                                     id="maintenance-work-undefined">Cambiar filtro de aceite</span>
                                                <ul class="maintenance-work" id="maintenance-work-undefined-works"></ul>
                                            </li>
                                            <li class="col-12"><span class="maintenance-work"
                                                                     id="maintenance-work-undefined">Cambiar filtro de aire</span>
                                                <ul class="maintenance-work" id="maintenance-work-undefined-works"></ul>
                                            </li>
                                            <li class="col-12"><span class="maintenance-work"
                                                                     id="maintenance-work-undefined">Cambiar filtro de combustible</span>
                                                <ul class="maintenance-work" id="maintenance-work-undefined-works"></ul>
                                            </li>
                                            <li class="col-12"><span class="maintenance-work"
                                                                     id="maintenance-work-undefined">Cambiar filtro del habitáculo</span>
                                                <ul class="maintenance-work" id="maintenance-work-undefined-works"></ul>
                                            </li>
                                            <li class="col-12"><span class="maintenance-work"
                                                                     id="maintenance-work-undefined">Cambiar aceite de motor</span>
                                                <ul class="maintenance-work" id="maintenance-work-undefined-works"></ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </form>

                            <h3 class="price-rate">Costo  <span>$150.00 - $250.00</span></h3>

                            <div class="col-lg-12 col-md-12 text-center">
                                <button type="submit" class="default-btn border-radius">Reservar <i class='bx bx-plus'></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Horario de atención</h3>

                        <ul class="listing-widget-list">
                            <li>Lunes <span>9:00 AM - 8:00 PM</span></li>
                            <li>Domingo <span>Abierto todo el día</span></li>
                            <li>Sábado <span>7:00 AM - 9:00 PM</span></li>
                            <li>Viernes <span>Abierto todo el día</span></li>
                            <li>Miércoles <span>9:00 AM - 8:00 PM</span></li>
                            <li>Jueves <span>Abierto todo el día</span></li>
                            <li>Martes <span>Abierto todo el día</span></li>
                        </ul>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Contacto</h3>

                        <ul class="listing-widget-list">
                            <li>Dirección : <span> Guayaquil</span></li>
                            <li>Telefono : <span><a href="tel:+3(567)347654">+593(567)347654</a></span></li>
                            <li>Correo : <span><a href="mailto:pilar@listing.com">taller@listing.com</a></span></li>
                            <li>Sitio : <span> <a href="#">https://taller.com</a></span></li>
                        </ul>

                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Rango de precio</h3>

                        <h3 class="price-title">Precio : <span>$ 67.00 - $ 390.00</span></h3>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Publicado por :</h3>

                        <div class="listing-side-host">
                            <img src="assets/img/place-list/listing-details-pro3.png" alt="Images">
                            <h3>Chichi Valoy</h3>
                            <span>20 Servicios publicados</span>
                        </div>

                        <ul class="side-host-list">
                            <li><a href="#"><i class='bx bxs-user'></i></a></li>
                            <li><a href="#"><i class='bx bx-envelope'></i></a></li>
                            <li> <span>¡Reclama ahora!</span></li>
                        </ul>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Etiquetas de noticias</h3>

                        <ul class="listing-side-tag">
                            <li><a href="#">#Taller</a></li>
                            <li><a href="#"><b>#Lavado</b></a></li>
                            <li><a href="#">#Cambio Aceite</a></li>
                            <li><a href="#"><b>#Chevrolet</b></a></li>
                            <li><a href="#">#KIA</a></li>
                            <li><a href="#">#Eléctrico</a></li>
                        </ul>
                    </div>

                    <div class="listing-widget-side listing-blog-post">
                        <h3 class="title">Etiquetas de noticias</h3>

                        <ul class="listing-widget-post">
                            <li>
                                <div class="img">
                                    <img src="assets/img/place-list/listing-blog.jpg" alt="Images">
                                </div>

                                <div class="content">
                                    <span>Taller</span>
                                    <h4>Frenazo</h4>
                                    <p><i class="flaticon-cursor"></i> Guayaquil, Guayas</p>
                                </div>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="assets/img/place-list/listing-blog2.jpg" alt="Images">
                                </div>

                                <div class="content">
                                    <span>Almacén</span>
                                    <h4>Partes baratas</h4>
                                    <p><i class="flaticon-cursor"></i> Guayaquil, Guayas</p>
                                </div>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="assets/img/place-list/listing-blog3.jpg" alt="Images">
                                </div>

                                <div class="content">
                                    <span>Taller</span>
                                    <h4>Reparándolo</h4>
                                    <p><i class="flaticon-cursor"></i> Guayaquil, Guayas</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
