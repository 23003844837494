<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Acerca de</h3>
            <ul>
                <li><a href="index.html">Inicio</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Páginas</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Acerca de</li>
            </ul>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <span>Conózcanos</span>
                        <h2>Quienes somos</h2>
                        <p>En Autoalert, nos dedicamos a revolucionar la forma en que los propietarios de vehículos, talleres y vendedores de repuestos interactúan y realizan negocios. Somos una plataforma integral que conecta a los usuarios que utilizan sus vehículos como medio de ingreso, como taxis y expresos, con los mejores talleres y proveedores de repuestos en Ecuador. Nuestro objetivo es simplificar y optimizar el proceso de búsqueda, compra y mantenimiento de vehículos, ofreciendo una experiencia fluida y eficiente para todos nuestros usuarios.</p>
                    </div>

                    <div class="about-list">
                        <ul>
                            <!--<li><i class="flaticon-curve-arrow"></i> Confiabilidad<br />
                                Confía en nosotros para ofrecerte servicios y productos de alta calidad, con la transparencia y el compromiso que mereces.</li>
                            <li><i class="flaticon-curve-arrow"></i> Innovación
                                Estamos a la vanguardia de la tecnología para brindarte soluciones modernas que faciliten tu vida.</li>
                            <li><i class="flaticon-curve-arrow"></i> Eficiencia
                                Optimiza tu tiempo y recursos con nuestra plataforma.</li>-->
                        </ul>
                    </div>
                    <a routerLink="/#" class="default-btn border-radius">Nuestro equipo <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/about-img.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="choose-area">
    <div class="container">
        <div class="section-title text-center">
            <span>Las razones para escogernos</span>
            <h2>Porque nosotros</h2>
        </div>

        <div class="choose-width pt-100 pb-70">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="choose-card">
                        <i class="flaticon-phone-call"></i>
                        <h3>Confiabilidad</h3>
                        <p>Confía en nosotros para ofrecerte servicios y productos de alta calidad, con la transparencia y el compromiso que mereces.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="choose-card">
                        <i class="flaticon-web-page"></i>
                        <h3>Innovación</h3>
                        <p>Estamos a la vanguardia de la tecnología para brindarte soluciones modernas que faciliten tu vida.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="choose-card">
                        <i class="flaticon-support"></i>
                        <h3>Eficiencia</h3>
                        <p>Optimiza tu tiempo y recursos con nuestra plataforma.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--

<div class="video-area video-area-bg">
    <div class="container">
        <div class="video-content">
            <h2>¿Estás listo para comenzar tu viaje?</h2>
            <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="play-btn"><i class='bx bx-play' ></i></a>
        </div>
    </div>
</div>

<div class="counter-area">
    <div class="container">
        <div class="counter-bg">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>1254</h3>
                        <span>Nuevos visitantes cada semana</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>23165</h3>
                        <span>Nuevos visitantes cada día</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>4563</h3>
                        <span>Pemios increíbles ganados</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>880</h3>
                        <span>Nuevo listado cada semana</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="process-area">
    <div class="process-into process-into-2  pt-100 pb-70">
        <div class="container">
            <div class="section-title text-center">
                <span>Proceso</span>
                <h2>Vea <b>cómo funciona</b></h2>
                <p>Porem ipsum dolor sit ame consectetur adipisicing incididunt </p>
            </div>

            <div class="row pt-45">
                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number1">1</div>
                        <i class="flaticon-position"></i>
                        <div class="content">
                            <h3>Encuentre el servicio deseado</h3>
                            <p>Lorem ipsum dolor sit amet, consetetur adipisicing elit, sed do eiusmod tempor  quam voluptatem.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number2 active">2</div>
                        <i class="flaticon-to-do-list"></i>
                        <div class="content">
                            <h3>Elige el horario</h3>
                            <p>Lorem ipsum dolor sit amet, consetetur adipisicing elit, sed do eiusmod tempor  quam voluptatem.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="process-item">
                        <div class="process-item-number number3">3</div>
                        <i class="flaticon-box"></i>
                        <div class="content">
                            <h3>Agende</h3>
                            <p>Lorem ipsum dolor sit amet, consetetur adipisicing elit, sed do eiusmod tempor  quam voluptatem.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="process-line-2"><img src="assets/img/shape/process-line2.png" alt="Images"></div>
    </div>
</section>

<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Equipo</span>
            <h2>Nuestro equipo</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <a routerLink="/team"><img src="assets/img/team/team1.jpg" alt="Team Images"></a>
                    <div class="content">
                        <h3><a href="#">Armando Pedro</a></h3>
                        <span>Desarrollador</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <a routerLink="/team"><img src="assets/img/team/team2.jpg" alt="Team Images"></a>
                    <div class="content">
                        <h3><a href="#">Adrian Gurvitz</a></h3>
                        <span>Diseñador</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="team-card">
                    <a routerLink="/team"><img src="assets/img/team/team3.jpg" alt="Team Images"></a>
                    <div class="content">
                        <h3><a href="#">Juan Bau</a></h3>
                        <span>Economista</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="client-area pt-100">
    <div class="container">
        <div class="client-bg">
            <div class="client-slider owl-carousel owl-theme">
                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Victor Manuel</h3>
                                <span>Taxirutas</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Orlando Netti</h3>
                                <span>Intercoperativas</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>José Augusto</h3>
                                <span>Interciudadelas</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="application-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="application-content">
                    <div class="section-title">
                        <span>Descargar Aplicación</span>
                        <h2>Obtenga más en nuestra aplicación Siéntese y disfrute</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                    </div>

                    <div class="application-btn">
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/google-play.svg" alt="svg">
                            <div class="btn-content">
                                <span>APL. ANDROID EN</span>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/company.svg" alt="svg">
                            <div class="btn-content">
                                <span>DESCARGA EN LA</span>
                                <h3>App Store</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="application-img-two">
                    <img src="assets/img/mobile2.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
-->

<app-footer-style-one></app-footer-style-one>
