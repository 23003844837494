<div class="modal-header">
    <h4 class="modal-title">Cotizar</h4>
</div>
<div class="modal-body">
    <form>
        <div class="form-group" *ngFor="let field of fields">
            <label>{{ field.label }}</label>
            <select class="form-control" [(ngModel)]="field.selectedValue" name="{{field.name}}">
                <option *ngFor="let option of field.options" [value]="option">{{ option }}</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">Cotizar</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cerrar</button>
</div>
