<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Categoría</h3>
            <ul>
                <li><a href="index.html">Inicio</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Página</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Categoría</li>
            </ul>
        </div>
    </div>
</div>

<section class="category-area pt-100 pb-70">
    <div class="container">
        <div class="section-title ">
            <span>Las Categorías</span>
            <h2>Buscar <b>por categoría</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <!--<a routerLink="/category"><i class="flaticon-bake"></i></a>-->
                    <a routerLink="/category"><h3>Mecánico </h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <!--<a routerLink="/category"><i class="flaticon-hotel"></i></a>-->
                    <a routerLink="/category"><h3>Electromecánico </h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <!--<a routerLink="/category"><i class="flaticon-barbell"></i></a>-->
                    <a routerLink="/category"><h3>Hidráulico</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <!--<a routerLink="/category"><i class="flaticon-store"></i></a>-->
                    <a routerLink="/category"><h3>Transmisión</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <!--<a routerLink="/category"><i class="flaticon-event"></i></a>-->
                    <a routerLink="/category"><h3>A/C</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <!--<a routerLink="/category"><i class="flaticon-flower"></i></a>-->
                    <a routerLink="/category"><h3>Caja de cambios</h3></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="city-area-two pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="section-title">
                    <span>Ciudades</span>
                    <h2>Explora las mejores servicios de la zona</h2>
                    <p>Porem ipsum dolor sit ame consectetur adipisicing eli sed usmod tempor incididunt </p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="city-btn">
                    <a routerLink="/" class="default-btn">Ver todos los lugares <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area5.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>Mecánica Básica</h3></a>
                        <!--<span>17 sitios</span>-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area6.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>Revisión del sistema de frenos</h3></a>
                        <!--<span>13 sitios</span>-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area7.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>Revisión del sistema de enfriamiento</h3></a>
                        <<!--span>13 sitios</span>-->
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area8.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>Respuestos</h3></a>
                        <!--<span>30 sitios</span>-->
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 offset-md-3 offset-lg-0">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area9.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>Cambio de amortiguadores</h3></a>
                        <!--<span>34 sitios</span>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>
