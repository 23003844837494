<div class="user-area">
    <div class="container-fluid m-0">
        <div class="row align-items-center">
            <div class="col-lg-7 col-xl-6  p-0">
                <div class="user-img">
                    <img src="assets/img/login-img.jpg" alt="Images">
                </div>
            </div>

            <div class="col-lg-5 col-xl-6">
                <div class="col-12 col-md-6 col-lg-8">
                    <div class="garages_join-form">
                        <h2>Sé parte de nuestra <strong>RED DE TALLERES/Almacenes RECOMENDADOS</strong> de Autoalert</h2>
                        <form id="garages-join-form" action="/garages/ask_join?locale=es" method="post">
                            <div class="row">
                                <div class="col-2 form-ico">
                                    <img alt="Taller" width="33" height="33" src="https://www.autingo.es/assets/form/wrench-ico-1d1398ec8400c46aa57a2b68a2ac506b720c1f3691aedc0a38cc6c2e2fa1dd11.svg">
                                </div>
                                <div class="col-10">
                                    <input class="form-control" type="text" name="name" placeholder="Nombre del taller">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2 form-ico">
                                    <img alt="CIF/NIF" width="33" height="33" src="https://www.autingo.es/assets/form/cifnif-ico-03b65b192e52d78fa5d5b4467f42bc36aa7e702f22c20bc827a70593b409f8c2.svg">
                                </div>
                                <div class="col-10">
                                    <input class="form-control" type="text" name="id" placeholder="RUC / CI">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2 form-ico">
                                    <img alt="Describe tu problema" width="33" height="33" src="https://www.autingo.es/assets/form/email-ico-d29d09fbd64777582f834103a319824d7a0f40ba749b8cafafbf4096bd89ef5b.svg">
                                </div>
                                <div class="col-10">
                                    <input class="form-control" type="email" name="email" placeholder="Correo electrónico">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2 form-ico">
                                    <img alt="Describe tu problema" width="33" height="33" src="https://www.autingo.es/assets/form/phone-ico-8cbee82706e4b14c07ba276d642c9a31728e6a5b3ca4bb749cc25f3a2cd1238d.svg">
                                </div>
                                <div class="col-10">
                                    <input class="form-control" name="phone" type="text" placeholder="Teléfono">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2 form-ico">
                                    <img alt="Describe tu problema" width="33" height="33" src="https://www.autingo.es/assets/form/web-ico-a58422a083893902ad3dfa9c787d8bd2062ac6fea335f0b3ed17501e8366eadc.svg">
                                </div>
                                <div class="col-10">
                                    <input class="form-control" type="text" name="web" placeholder="Página web">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2 form-ico">
                                    <img alt="Describe tu problema" width="33" height="33" src="https://www.autingo.es/assets/form/manager-ico-15bcf099238586ee221ca786c17640ca901be9a3664399152dd8869beb0786c5.svg">
                                </div>
                                <div class="col-10">
                                    <input class="form-control" type="text" name="manager" placeholder="Nombre completo del gerente">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2 form-ico">
                                    <img alt="Describe tu problema" width="33" height="33" src="https://www.autingo.es/assets/form/address-ico-e7d86f0f602b426d9190026c936c332c89d299a07d82b3a6ad9177ca610b7b9f.svg">
                                </div>
                                <div class="col-10">
                                    <textarea class="form-control" name="address" placeholder="Dirección completa"></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2 form-ico">
                                    <img alt="Describe tu problema" width="33" height="33" src="https://www.autingo.es/assets/form/time-ico-1a8907cce1b469ea03e8d65da6241dc37eb96c9db20d4fe8286c2d73b4a3fd96.svg">
                                </div>
                                <div class="col-5">
                                    <input class="form-control" name="hour_open" type="text" placeholder="Apertura">
                                </div>
                                <div class="col-5">
                                    <input class="form-control" name="hour_close" type="text" placeholder="Cierre">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2 form-ico">
                                    <img alt="Describe tu problema" width="33" height="33" src="https://www.autingo.es/assets/form/price-ico-8da21eed3adf723483f93e65c1d9caed17363617fb435eb397b471661907adda.svg">
                                </div>
                                <div class="col-10">
                                    <input class="form-control" name="work_price" type="text" placeholder="Precio mano de obra">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" value="1" name="multibrand">
                                        <span class="custom-control-indicator"></span>
                                        <span class="custom-control-description" data-description="">
<small>Deseo estar en al Red de <strong>Almacenes Recomendados</strong></small>
</span>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" value="1" name="recommended">
                                        <span class="custom-control-indicator"></span>
                                        <span class="custom-control-description" data-description="">
<small>Deseo estar en al Red de <strong>Talleres Recomendados</strong></small>
</span>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" value="1" name="accept_terms">
                                        <span class="custom-control-indicator"></span>
                                        <span class="custom-control-description" data-description="">
<small>He leído y acepto los <a href="/terminos?locale=es" target="_blank">Términos y condiciones</a> y la <a href="/terms-condition" target="_blank">Política de privacidad</a></small>
</span>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button type="submit" class="btn btn-primary">Unirme ahora</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

