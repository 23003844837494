import { Component, OnInit } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SelectModalComponent} from "../select-modal/select-modal.component";

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }
    openModal() {
        const modalRef = this.modalService.open(SelectModalComponent);
        modalRef.componentInstance.formData = {}; // Pasar datos iniciales si es necesario

        modalRef.result.then((result) => {
            console.log('Modal cerrado con: ', result);
        }).catch((error) => {
            console.log('Modal cerrado con error: ', error);
        });
    }
}
