<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Precio</h3>
            <ul>
                <li><a href="index.html">Inicio</a></li>
                <!--<li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>-->
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Precio</li>
            </ul>
        </div>
    </div>
</div>

<section class="pricing-area ptb-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Precios Taller/Almacen</span>
            <h2>Nuestras tablas de precios</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                    <div class="content">
                        <i class="flaticon-deal"></i>
                        <h3>PRUEBA GRATIS</h3>
                        <h2><sup>$</sup>0</h2>
                        <span>1 MES GRATIS</span>
                    </div>
                    <ul>
                        <li>Perfil del taller con detalles básicos (ubicación, horario, servicios ofrecidos).</li>
                        <li>Funcionalidad básica de agendamiento de citas.</li>
                        <li>Acceso a CRM básico para gestión de clientes.</li>
                        <li></li>
                    </ul>
                    <a routerLink="/contact" class=" default-btn border-radius">Elegir <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                    <div class="content">
                        <i class="flaticon-megaphone"></i>
                        <h3>Plan Básico</h3>
                        <h2><sup>$</sup>50</h2>
                        <span>cada mes</span>
                    </div>
                    <ul>
                        <li>Perfil del taller con detalles básicos (ubicación, horario, servicios ofrecidos).</li>
                        <li>Recepción de reseñas y calificaciones.</li>
                        <li>Funcionalidad básica de agendamiento de citas.</li>
                        <li>Botón de pago seguro integrado.</li>
                    </ul>
                    <a routerLink="/contact" class=" default-btn border-radius">Elegir <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="price-card">
                    <div class="content">
                        <i class="flaticon-diamond"></i>
                        <h3>Plan Premium</h3>
                        <h2><sup>$</sup>100</h2>
                        <span>cada mes</span>
                    </div>
                    <ul>
                        <li>Todas las funcionalidades del Plan Básico.</li>
                        <li>Acceso a CRM básico para gestión de clientes.</li>
                        <li>Herramientas de marketing digital (promociones, campañas).</li>
                        <li>Botón de pago seguro integrado.</li>
                        <li>Soporte prioritario.</li>
                    </ul>
                    <a routerLink="/contact" class=" default-btn border-radius">Elegir <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-3">

            </div>
            <div class="col-lg-4 col-md-6">
                <p class="add"><strong class="">Ventas de Repuestos:</strong>5% por venta</p>
                <p class="add"><strong class="">Servicios de Talleres:</strong>3% por transacción</p>
            </div>
            <div class="col-lg-4 col-md-3">

            </div>
        </div>
    </div>

</section>

<section class="pricing-area ptb-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Precios Usuario</span>
            <h2>Nuestras tablas de precios</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                    <div class="content">
                        <i class="flaticon-deal"></i>
                        <h3>PRUEBA GRATIS</h3>
                        <h2><sup>$</sup>0</h2>
                        <span>10 días</span>
                    </div>
                    <ul>
                        <li>Acceso a la búsqueda de talleres y almacenes de repuestos.</li>
                        <li>Lectura y escritura de reseñas.</li>
                        <li>Agendamiento de citas con serv básicos </li>
                        <li></li>
                    </ul>
                    <a routerLink="/contact" class=" default-btn border-radius">Elegir <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                    <div class="content">
                        <i class="flaticon-megaphone"></i>
                        <h3>Plan Básico</h3>
                        <h2><sup>$</sup>5</h2>
                        <span>cada mes</span>
                    </div>
                    <ul>
                        <li>Descuentos básicos y acceso a promociones.</li>
                        <li>Acceso a la búsqueda de talleres y almacenes de repuestos.</li>
                        <li>Agendamiento de citas con confirmación.</li>
                        <li>Lectura y escritura de reseñas.</li>
                    </ul>
                    <a routerLink="/contact" class=" default-btn border-radius">Elegir <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="price-card">
                    <div class="content">
                        <i class="flaticon-diamond"></i>
                        <h3>Plan Premium</h3>
                        <h2><sup>$</sup>10</h2>
                        <span>cada mes</span>
                    </div>
                    <ul>
                        <li>Todas las funcionalidades del Plan Básico.</li>
                        <li>Acceso a ofertas y promociones exclusivas.</li>
                        <li>Historial de servicios y compras.</li>
                        <li>Atención al cliente prioritaria.</li>
                    </ul>
                    <a routerLink="/contact" class=" default-btn border-radius">Elegir <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-style-two></app-footer-style-two>
