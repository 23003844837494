<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg8">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Team</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Team</li>
            </ul>
        </div>
    </div>
</div>

<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Team</span>
            <h2>Our Skilled Team</h2>
        </div>
        
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <img src="assets/img/team/team1.jpg" alt="Team Images">
                    <div class="content">
                        <h3>Austin Evon</h3>
                        <span>Co-manager associated</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <img src="assets/img/team/team2.jpg" alt="Team Images">
                    <div class="content">
                        <h3>Taylor Anderson</h3>
                        <span>Business consultant</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <img src="assets/img/team/team6.jpg" alt="Team Images">
                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>Co-Founder</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <img src="assets/img/team/team3.jpg" alt="Team Images">
                    <div class="content">
                        <h3>Jorgia Klev</h3>
                        <span>Business Manger</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <img src="assets/img/team/team4.jpg" alt="Team Images">
                    <div class="content">
                        <h3>Steven Smith</h3>
                        <span>CEO & Founder</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <img src="assets/img/team/team7.jpg" alt="Team Images">
                    <div class="content">
                        <h3>Amelia Smith</h3>
                        <span>Programer</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <img src="assets/img/team/team5.jpg" alt="Team Images">
                    <div class="content">
                        <h3>Liam Smith</h3>
                        <span>Marketing</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <img src="assets/img/team/team8.jpg" alt="Team Images">
                    <div class="content">
                        <h3>Sofia Smith</h3>
                        <span>Co-Founder</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="team-card">
                    <img src="assets/img/team/team9.jpg" alt="Team Images">
                    <div class="content">
                        <h3>Ethan Smith</h3>
                        <span>CEO & Founder</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>